import React from 'react'
import PropTypes from 'prop-types'

const SvgSustainability = ({
    className,
    height,
    width,
    fill = '#c19678'
}) => {
    return (
        <svg width={ width } height={ height } className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 45.41 40">
            <g id="c">
                <g>
                    <path d="M5.66,20.41l-2.83-2.83-2.83,2.83,2.81-2.8-.03,2.39c-.02,1.58,.16,4.79,1.72,8,3.08,7.07,10.13,12,18.33,12s15.25-4.93,18.33-12" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M39.75,19.59l2.83,2.83,2.83-2.83-2.81,2.8,.03-2.39c.02-1.58-.16-4.79-1.72-8C37.83,4.93,30.78,0,22.58,0S7.33,4.93,4.25,12" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M22.7,18.43c1.79-1.03,2.99-2.97,2.99-5.19s-1.21-4.15-2.99-5.19c-1.79,1.03-2.99,2.97-2.99,5.19s1.21,4.15,2.99,5.19" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="22.7" y1="28.13" x2="22.7" y2="32" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="22.7" y1="28.13" x2="22.7" y2="18.43" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="22.7" y1="18.43" x2="22.7" y2="14.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M18.11,23.54c.53-1.99,.02-4.21-1.55-5.78-1.57-1.57-3.79-2.08-5.78-1.55-.53,1.99-.02,4.21,1.55,5.78,1.57,1.57,3.79,2.08,5.78,1.55,0,0,0,0,0,0" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="15" y1="20.43" x2="18.11" y2="23.54" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <polyline points="22.7 28.13 18.11 23.54 18.11 23.54" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M27.3,23.54s0,0,0,0c1.99,.53,4.21,.02,5.78-1.55,1.57-1.57,2.08-3.79,1.55-5.78-1.99-.53-4.21-.02-5.78,1.55-1.57,1.57-2.08,3.79-1.55,5.78" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="27.3" y1="23.54" x2="30.4" y2="20.43" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="27.3" y1="23.54" x2="22.7" y2="28.13" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                </g>
            </g>
        </svg>
    )
}

const SvgQuality = ({
    className,
    height,
    width,
    fill = '#c19678'
}) => {
    return (
        <svg width={ width } height={ height } className={ className } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.25 44.14">
            <g id="c">
                <rect x="8.62" y="31.51" width="24" height="8" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <polygon points="36.62 39.51 40.62 43.51 28.62 43.51 28.62 39.51 32.62 39.51 32.62 35.51 40.62 35.51 36.62 39.51" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <polygon points="12.62 39.51 12.62 43.51 .62 43.51 4.62 39.51 .62 35.51 8.62 35.51 8.62 39.51 12.62 39.51" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <g>
                    <polygon points="23.48 7.57 24.15 11.51 20.62 9.65 17.1 11.51 17.77 7.57 14.91 4.78 18.86 4.21 20.43 1.03 20.62 .62 20.82 1.03 22.39 4.21 26.34 4.78 23.48 7.57" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <polygon points="23.48 23.57 24.15 27.51 20.62 25.65 17.1 27.51 17.77 23.57 14.91 20.78 18.86 20.21 20.43 17.03 20.62 16.62 20.82 17.03 22.39 20.21 26.34 20.78 23.48 23.57" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                </g>
                <g>
                    <polygon points="9.77 15.57 10.44 19.51 6.91 17.65 3.38 19.51 4.05 15.57 1.19 12.78 5.14 12.21 6.71 9.03 6.91 8.62 7.11 9.03 8.68 12.21 12.62 12.78 9.77 15.57" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <polygon points="37.2 15.57 37.87 19.51 34.34 17.65 30.81 19.51 31.48 15.57 28.62 12.78 32.57 12.21 34.14 9.03 34.34 8.62 34.54 9.03 36.11 12.21 40.06 12.78 37.2 15.57" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                </g>
            </g>
        </svg>
    )
}

const SvgWithoutMarkups = ({
    className,
    height,
    width,
    fill = '#c19678'
}) => {
    return (
        <svg width={ width } height={ height } className={ className } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.02 42.02">
            <g id="c">
                <g>
                    <path d="M21.39,15.04c1.66,0,3.04,1.38,3.04,3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M21.39,27.2c-1.68,0-3.04-1.36-3.04-3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="27.2" x2="21.39" y2="28.72" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="15.04" x2="21.39" y2="13.52" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="15.04" x2="21.39" y2="21.12" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M21.39,15.04c-1.68,0-3.04,1.36-3.04,3.04s1.36,3.04,3.04,3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <line x1="21.39" y1="21.12" x2="21.39" y2="27.2" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                    <path d="M21.39,21.12c1.68,0,3.04,1.36,3.04,3.04s-1.36,3.04-3.04,3.04" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                </g>
                <polyline points="37.39 8.63 37.39 21.6 17.6 41.39 .62 24.42 20.42 4.62 37.39 4.62 37.39 8.63" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M34.57,7.45c-.72-.72-1.17-1.72-1.17-2.83" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M37.39,8.62h0" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
                <path d="M33.39,4.63h0c0-2.21,1.79-4,4-4s4,1.79,4,4-1.79,4-4,4" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" />
            </g>
        </svg>
    )
}

const sharedPropTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fill: PropTypes.string
}

SvgSustainability.propTypes = sharedPropTypes
SvgQuality.propTypes = sharedPropTypes
SvgWithoutMarkups.propTypes = sharedPropTypes

export {
    SvgSustainability,
    SvgQuality,
    SvgWithoutMarkups
}

import React, { useState } from 'react'
import classNames from 'classnames'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import { ReviewCard } from '@saatva-bits/pattern-library.components.review-card'
import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { Button } from '@saatva-bits/pattern-library.components.button'
import PropTypes from 'prop-types'

import { pushDataLayerEvent } from '@/utils/datalayer'

import styles from './ReviewSection.module.scss'

const ReviewSection = ({ reviews, withViewMoreBtn = true }) => {
    const { isDesktop } = useBreakpoints('desktop')
    const [isOpen, setIsOpen] = useState(false)
    const [modalData, setModalData] = useState(null)

    if (!reviews || !reviews.length) {
        return null
    }

    const openModal = (reviewData) => {
        setIsOpen(true)
        setModalData({
            ...reviewData,
            description: reviewData.body,
            productURL: reviewData.productUrl,
            starsCount: reviewData.starCount,
            noHover: true,
            displayButton: false,
            containerClassName: styles.reviewModalContent
        })
    }

    const closeModal = () => {
        pushDataLayerEvent({
            'event': 'exitModal'
        })
        setIsOpen(false)
        setModalData(false)
    }

    const swipeSlide = () => {
        pushDataLayerEvent({
            'event': 'homepagereviewSwipe'
        })
    }

    const titleClassnames = classNames('t-heading2 u-textCenter u-marginHorizontal--2xl',
        styles.reviewSectionTitle, 
        {'u-marginBottom--none': !withViewMoreBtn }
    )

    return <div className={styles.reviewSection}>
        <div className='container u-textCenter'>
            <h2 className={titleClassnames}>
                See what happy Saatva sleepers are saying
            </h2>
            { withViewMoreBtn &&
                <Button
                    kind="primary"
                    className={styles.viewMoreReviewsBtn}
                    href="/reviews#reviewSearch"
                    data-selector='view-more-reviews-btn'
                >
                    Read all reviews
                </Button>
            }
        </div>
        <SimpleSlider
            sliderLabel="reviews"
            infinite
            pageDotsAndNumbers
            className={styles.reviewSlider}
            shownSize={3}
            peekable={true}
            isCart={true}
            onChange={() => swipeSlide()}
            onChangeOffset={isDesktop ? 3 : 1}
            transitionDuration={200}
        >
            {reviews.map((review, index) => (
                <ReviewCard
                    index={index}
                    key={`review-card-${index}`}
                    containerClassName={styles.reviewCard}
                    maxLength={288}
                    readMoreBtnAction={() => openModal(review)}
                    {...review}
                    description={review.body}
                    productURL={review.productUrl}
                    starsCount={review.starCount}
                />
            ))}
        </SimpleSlider>
        {isOpen &&
            <Modal
                modalLabel="review modal"
                type="popup"
                className={styles.reviewModal}
                isModalVisible={isOpen}
                onModalClose={closeModal}
                closeButtonClass={styles.dialogClose}
            >
                <ReviewCard {...modalData} />
            </Modal>
        }
    </div>
}

ReviewSection.propTypes = {
    reviewType: PropTypes.string,
    withViewMoreBtn: PropTypes.bool,
}

export default ReviewSection
